import { createContext } from 'react'
import { useFetch } from './use-fetch'


const BASE_URL = process.env.REACT_APP_API_BASE || ''
export const APIContext = createContext({})


export const APIAware = ({children}) => {
  const defaultConfig = JSON.parse(localStorage.getItem('APIConfig')) || {}
  const [config, { loading, error }] = useFetch(`${BASE_URL}/config`)

  if (config && !loading && !error) {
    localStorage.setItem('APIConfig', JSON.stringify(config))
  }

  return <APIContext.Provider value={config || defaultConfig}>
    {children}
  </APIContext.Provider>
}
