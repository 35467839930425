import { useCallback } from 'react'
import { useAuth } from '../../auth'
import { useContactModal } from './contact-modal'


export const ContactButton = ({ listing }) => {
  const auth = useAuth()
  const modal = useContactModal(listing)

  const handler = useCallback(() => {
    if (auth.loggedIn || listing.contact) {
      modal.open()
    } else {
      auth.initiate(() => modal.open())
    }
  }, [auth, listing, modal])

  return (
    <>
      <button onClick={handler}><span><i>📞</i> Contact</span></button>
      {modal.component}
    </>
  )
}
