import { useEffect } from 'react'
import { useIntersection } from 'react-use'


export const useOnScreen = (target, handler) => {
  const intersection = useIntersection(target, {
    root: null,
  })

  useEffect(() => {
    if (handler) {
      if (intersection && intersection.intersectionRatio > 0) {
        handler(true)
      } else {
        handler(false)
      }
    }
  }, [handler, intersection])

  return intersection && intersection.intersectionRatio > 0
}
