import { createContext, useState } from 'react'
import { useEmailModal } from './email-modal'
import { useCodeModal } from './code-modal'


export const AuthContext = createContext({})


export const AuthAware = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('VitrineAuthToken'))

  const login = token => {
    localStorage.setItem('VitrineAuthToken', token)
    setToken(token)
  }

  const logout = () => {
    localStorage.removeItem('VitrineAuthToken')
    setToken(null)
  }

  const emailModal = useEmailModal()
  const codeModal = useCodeModal()

  const initiate = next => {
    if (!token) {
      emailModal.open(email => codeModal.open(token => {
        login(token)
        setTimeout(() => next(), 1)
      }, { email }))
    }
  }

  return (
    <AuthContext.Provider value={{ token, loggedIn: !!token, login, logout, initiate }}>
      {children}
      {emailModal.component}
      {codeModal.component}
    </AuthContext.Provider>
  )
}
