import appleIcon from './apple.svg'
import googlePlayIcon from './google-play.svg'


export const GetTheAppButton = () => {
  return <>
    <a className='btn' justiphone='true'
      href='https://apps.apple.com/us/app/vitrine/id1604353794'
      target='_blank' rel='noreferrer'>
      <span>
        <img className='icon' src={appleIcon} alt='apple icon' /> Get the App
      </span>
    </a>
    <a className='btn' justandroid='true'
      href='https://play.google.com/store/apps/details?id=cloud.vitrin'
      target='_blank' rel='noreferrer'>
      <span>
        <img className='icon' src={googlePlayIcon} alt='google play icon' /> Get the App
      </span>
    </a>
    <a className='btn' noandroid='true' noiphone='true'
      href='https://vitrine.cloud'
      target='_blank' rel='noreferrer'
    >
      Get the App
    </a>
  </>
}