import { useState } from 'react'
import { useDeepCompareEffectNoCheck as useDeepEffect } from 'use-deep-compare-effect'


export const useFetch = (url, options, skip) => {
  const [result, setResult] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(undefined)

  useDeepEffect(() => {
    const controller = new AbortController()

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          ...options,
          signal: controller.signal,
        })

        if (controller.signal.aborted) {
          return
        }

        const data = await response.json()

        if (controller.signal.aborted) {
          return
        }

        if (response.status === 200) {
          setResult(data)
          setLoading(false)
        } else {
          setError(data)
          setLoading(false)
        }
      } catch (error) {
        if (controller.signal.aborted) {
          return
        }

        setError(error)
        setLoading(false)
      }
    }

    if (!skip) {
      fetchData()
    }

    return () => controller.abort()
  }, [url, options, skip])

  return [ result, { loading, error }]
}
