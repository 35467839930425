import styles from './index.module.css'

import { useCopyToClipboard } from 'react-use'
import { useModal } from '../../../util/modal'
import { useContactData } from './use-contact-data'


export const useContactModal = (listing) => {
  const { contact, error, loading } = useContactData(listing)
  const [{value}, copyToClipboard] = useCopyToClipboard()

  const format = () => {
    return `Phone Number: ${contact.phone_number}\nEmail Address: ${contact.email}`
  }

  const modal = useModal(
    <div className={styles.modal}>
      {loading && <>Loading...</>}
      {error && <>{error.message}</>}
      {contact && <>
        <a className='btn' href={`tel:${contact.phone_number}`}>
          <span><i>📞</i>Call on Phone</span>
        </a>
        <a className='btn' href={`sms:${contact.phone_number}`}>
          <span><i>💭</i>Send an SMS</span>
        </a>
        <a className='btn' href={`https://wa.me/${contact.phone_number}`}>
          <span><i>💬</i>Chat on WhatsApp</span>
        </a>
        <button onClick={() => copyToClipboard(format())}>
          <span>
            { value ?
              <><i>✔</i>Contact info copied!</> :
              <><i>📑</i>Copy contact info</>
            }
            
          </span>
        </button>
      </>}
    </div>
  )

  return modal
}
