import { BrowserRouter, Routes, Route } from 'react-router-dom'

import styles from './app.module.css'
import background from './logo-outline.svg'

import { APIAware } from './api'
import { MemStorageAware } from './util'
import { AuthAware } from './auth'
import { Listing } from './listing'
import { Grid } from './grid'


const App = () => {
  return (
    <MemStorageAware>
      <BrowserRouter>
        <div className={styles.backdrop}>
          <img src={background} alt='background' className={styles.background}/>
        </div>
        <div className={styles.container}>
            <APIAware>
              <AuthAware>
                <Routes>
                  <Route path='/listing/' element={<Grid />} />
                  <Route path='/listing/:id' element={<Listing />} />
                </Routes>
              </AuthAware>
            </APIAware>
        </div>
      </BrowserRouter>
    </MemStorageAware>
  )
}

export default App
