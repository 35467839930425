import { useEffect } from 'react'
import { useAPI } from '../api'


const isLogged = signature => {
  return localStorage.getItem('VitrineViewLog') === signature
}


const log = async (endpoint, signature) => {
  await fetch(`${endpoint}?signature=${signature}`, {
    method: 'put'
  })
  localStorage.setItem('VitrineViewLog', signature)
}


export const useListingStats = (listing) => {
  const api = useAPI()

  return useEffect(() => {
    if (
      !api.stats ||
      !listing ||
      !listing.signature ||
      isLogged(listing.signature)
    ) {
      return
    }

    const timeout = setTimeout(() => {
      log(`${api.stats}/listing/sharelink_view`, listing.signature)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [api.stats, listing])
}
