import { useAPI } from './use-api'
import { useFetch } from './use-fetch'


export const useMessages = () => {
  const api = useAPI()
  const defaultMsgs = JSON.parse(localStorage.getItem('APIMessages')) || {}
  const [messages, { loading, error }] = useFetch(api.messages, {}, !api || !api.messages)

  if (messages && !loading && !error) {
    localStorage.setItem('APIMessages', JSON.stringify(messages))
  }

  return messages || defaultMsgs
}
