import { useEffect } from 'react'
import { useAuth } from '../../../auth'
import { useAPI, useFetch } from '../../../api'


export const useContactData = listing => {
  const auth = useAuth()
  const api = useAPI()

  const [contact, { loading, error }] = useFetch(
    `${api.lists}/${listing.id}/contact`,
    {
      headers: {
        'Authorization': `Bearer ${auth.token}`
      }
    },
    !auth.loggedIn || listing.contact
  )

  useEffect(() => {
    if (!loading && error) {
      auth.logout()
    }
  })

  return {
    contact: listing.contact ? listing.contact : contact,
    loading: listing.contact ? false : loading,
    error
  }
}
