import { useMemStorage } from '../util'
import { useAPI, useFetch } from '../api'


export const useListingData = (listingId, skip) => {
  const api = useAPI()
  const storage = useMemStorage()

  const stored = storage.listings && storage.listings.find(l => l.id === listingId)

  const [listing, { loading, error }] = useFetch(
    `${api.lists}/${listingId}`,
    undefined,
    !!stored || skip || !api.lists
  )

  if (stored) {
    return { listing: stored, loading: false, error: undefined }
  } else {
    return { listing, loading, error }
  }
}
