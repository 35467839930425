import { useCallback, useState } from 'react'


export const useFetchPages = (opts) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(undefined)

  const _url = opts.url
  const url = useCallback(state => {
    if (typeof _url === 'function') {
      return _url(state)
    } else {
      return _url
    }
  }, [_url])

  const _options = opts.options
  const options = useCallback(state => {
    if (typeof _options === 'function') {
      return _options(state)
    } else {
      return _options || {}
    }
  }, [_options])

  const _rec = opts.receive
  const receive = useCallback(_rec, [_rec])

  const loadMore = useCallback(async (state) => {
    setLoading(true)
    const controller = new AbortController()

    try {
      const response = await fetch(url(state), {
        ...options(state),
        signal: controller.signal,
      })

      if (controller.signal.aborted) {
        return
      }

      const data = await response.json()

      if (response.status === 200) {
        receive(data)
        setLoading(false)
      } else {
        setError(data)
        setLoading(false)
      }
    } catch (error) {
      setError(error)
      setLoading(false)
    }

    return () => controller.abort()
  }, [url, options, receive])

  return { loading, error, loadMore }
}
