import { useCallback } from 'react'

import { useMemState } from '../util'
import { useAPI } from '../api'
import { useFetchPages } from '../api/use-fetch-pages'


const MAX_PAGE_SIZE = 8

const buildURL = (api, last) => {
  const params = last ? { count : MAX_PAGE_SIZE, last_submit_time: last } : { count: MAX_PAGE_SIZE }

  return api.lists + '?' + new URLSearchParams(params)
}

export const useGridData = () => {
  const api = useAPI()
  const [listings, setListings] = useMemState('listings', [])
  const [finished, setFinished] = useMemState('listings-finished', false)

  const url = useCallback(l => buildURL(api, l[l.length - 1]?.submit_time), [api])
  const {loading, error, loadMore} = useFetchPages({
    url,
    options: {},
    receive: (data) => {
      setListings(listings => [...listings, ...data])
      setFinished(data.length === 0)
    }
  })

  return { listings, loading, error, finished, loadMore }
}
