import { Link } from 'react-router-dom'
import { useMedia } from 'react-use'

import styles from './index.module.css'
import { Price } from '../../listing/price'
import { useRef } from 'react'


export const Cell = ({ listing }) => {
  const target = useRef(null)
  const autoplayOnMobile = useRef(Math.random() > 0.6)
  const isMobile = useMedia('(max-width: 768px)')

  return <Link to={listing.id} className={styles.cell} ref={target}>
    <video muted autoPlay={!isMobile || autoplayOnMobile.current}
    loop playsInline src={`${listing.video.url}#t=0.1`} preload='auto'/>
    <div className={styles.overlay}>
      <h2>{listing.title}</h2>
      <Price price={listing.price}/>
    </div>
  </Link>
}
