import styles from './index.module.css'

import { useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useClickAway } from 'react-use'



export const Overlay = ({ active, children }) => {
  const root = document.getElementById('modal-root')


  return createPortal(
    <div className={`${styles.overlay} ${active ? styles.active : styles.inactive}`}>
      {children}
    </div>,
    root
  )
}


export const useModal = (content) => {
  const next = useRef()
  const afterOpen = useRef()
  const [params, setParams] = useState({})
  const [active, setActive] = useState(false)

  const onOpen = callback => afterOpen.current = callback

  const open = (N, P) => {
    next.current = N
    setActive(true)
    setParams(P)

    if (afterOpen.current) {
      setTimeout(() => afterOpen.current(), 1)
    }
  }

  const close = V => {
    setActive(false)
    if (V && next.current) {
      next.current(V)
    }
  }

  const ref = useRef(null)
  useClickAway(ref, () => close())

  const component = (
    <Overlay active={active}>
      <div className={styles.modal} ref={ref}>
        {content}
        <br/><br/>
        <button onClick={() => close()}>Cancel</button>
      </div>
    </Overlay>
  )

  return {
    active,
    open,
    onOpen,
    close,
    params,
    component,
  }
}
