const CURRENCIES = {
  USD: { symbol: '$', name: 'US Dollar', },
  EUR: { symbol: '€', name: 'Euro', },
  GBP: { symbol: '£', name: 'British Pound', },
  CAD: { symbol: '$', name: 'Canadian Dollar', },
  AUD: { symbol: '$', name: 'Australian Dollar', },
  BRL: { symbol: 'R$', name: 'Brazilian Real', },
  CHF: { symbol: 'Fr', name: 'Swiss Franc', },
  CNY: { symbol: '¥', name: 'Chinese Yuan', },
  ALL: { symbol: 'Lek', name: 'Albanian Lek', },
  DKK: { symbol: 'kr', name: 'Danish Krone', },
  HKD: { symbol: '$', name: 'Hong Kong Dollar', },
  HRK: { symbol: 'kn', name: 'Croatian Kuna', },
  HUF: { symbol: 'Ft', name: 'Hungarian Forint', },
  IDR: { symbol: 'Rp', name: 'Indonesian Rupiah', },
  ILS: { symbol: '₪', name: 'Israeli New Sheqel', },
  INR: { symbol: '₹', name: 'Indian Rupee', },
  ISK: { symbol: 'kr', name: 'Icelandic Króna', },
  JPY: { symbol: '¥', name: 'Japanese Yen', },
  KRW: { symbol: '₩', name: 'South Korean Won', },
  MXN: { symbol: '$', name: 'Mexican Peso', },
  MYR: { symbol: 'RM', name: 'Malaysian Ringgit', },
  NOK: { symbol: 'kr', name: 'Norwegian Krone', },
  NZD: { symbol: '$', name: 'New Zealand Dollar', },
  PHP: { symbol: '₱', name: 'Philippine Peso', },
  PLN: { symbol: 'zł', name: 'Polish Zloty', },
  RON: { symbol: 'lei', name: 'Romanian Leu', },
  RUB: { symbol: '₽', name: 'Russian Ruble', },
  SEK: { symbol: 'kr', name: 'Swedish Krona', },
  SGD: { symbol: '$', name: 'Singapore Dollar', },
  THB: { symbol: '฿', name: 'Thai Baht', },
  TRY: { symbol: '₺', name: 'Turkish Lira', },
  ZAR: { symbol: 'R', name: 'South African Rand', },
  AMD: { symbol: 'դր.', name: 'Armenian Dram', },
  AZN: { symbol: '₼', name: 'Azerbaijan Manat', },
  BGN: { symbol: 'лв', name: 'Bulgarian Lev', },
  BYN: { symbol: 'Br', name: 'Belarusian Ruble', },
  GEL: { symbol: '₾', name: 'Georgian Lari', },
  KGS: { symbol: 'сом', name: 'Kyrgyzstani Som', },
  KZT: { symbol: '₸', name: 'Kazakhstani Tenge', },
  MDL: { symbol: 'L', name: 'Moldovan Leu', },
  MKD: { symbol: 'ден', name: 'Macedonian Denar', },
  RSD: { symbol: 'Дин.', name: 'Serbian Dinar', },
  UAH: { symbol: '₴', name: 'Ukrainian Hryvnia', },
  CZK: { symbol: 'Kč', name: 'Czech Koruna', },
}


function symbol(currency) {
  return CURRENCIES[currency].symbol || '??'
}


export const Price = ({ price }) => {
  if (typeof price === 'object') {
    return <h1>{symbol(price.currency)}{price.amount}</h1>
  } else {
    return <h1>€{price}</h1>
  }
}
