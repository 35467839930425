import { useRef } from 'react'

import logo from '../logo-type-dark.svg'
import { Footer } from '../footer'

import styles from './index.module.css'
import { Cell } from './cell'
import { useGridData } from './use-grid-data'
import { GetTheAppButton } from '../footer/get-app'
import { useOnScreen } from '../util'
import { useMedia } from 'react-use'


export const Grid = () => {
  const { listings, loading, error, finished, loadMore } = useGridData()

  const target = useRef(null)  
  useOnScreen(target, visible => {
    if (visible && !loading) {
      loadMore(listings)
    }
  })

  const isMobile = useMedia('(max-width: 768px)')

  return <>
    <div className={styles.container}>
      <div className={styles.header}>
        <a href='https://vitrine.cloud'>
          <img className={styles.logo} src={logo} alt='logo'/>
        </a>
        <h1 className={styles.title}>All Listings</h1>
        <hr justdesktop='true'/>
        <div className={styles.footer}>
          <Footer nolinks={isMobile}>
            <small justdesktop='true'>
              These listings where submitted via the Vitrine app, where you can find the latest listings from the community. You can also submit your own listings and find the best deals in your area.
              <br/><br/>
            </small>
            <GetTheAppButton/>
            <br/><br/>
          </Footer>
        </div>
      </div>
      <div className={styles.listings}>
        { listings.map(listing => <Cell listing={listing} key={listing.id}/>) }
        { error ? <div>Error: {error.message}</div> : <></> }
        <br/><br/>
        {
          !finished &&
          <button ref={target} disabled={loading} onClick={loadMore}>{ loading ? 'Loading...' : 'Load More' }</button>
        }
      </div>
    </div>
  </>
}
