import { useState, useRef } from 'react'
import { useModal } from '../../util/modal'
import { useAPI, useMessages } from '../../api'


export const useEmailModal = () => {
  const api = useAPI()
  const input = useRef()
  const messages = useMessages()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)


  const send = async () => {
    setLoading(true)
    setError(undefined)

    const res = await fetch(api.auth, {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify({ email })
    })

    setLoading(false)

    if (res.ok) {
      modal.close(email)
    } else {
      setError((await res.json()).message)
    }
  }

  const modal = useModal(
    <>
      <p dangerouslySetInnerHTML={{__html: messages?.auth?.contact || 'Login to contact our members.'}}/>
      <input ref={input} type='email' placeholder='✉ Email Address ...' className={error ? 'has-error' : ''}
        onInput={e => setEmail(e.target.value)}
      />
      {error && <p className='input-error'>{error}</p>}
      <br/>
      <button onClick={send}>
        <span>{ !loading ? <><i>👤</i> Login</> : 'Sending Verification Email ...' }</span>
      </button>
    </>
  )

  modal.onOpen(() => input.current.focus())

  return modal
}
