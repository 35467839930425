import styles from './index.module.css'

import { useCopyToClipboard } from 'react-use'


export const ShareButton = ({ listing }) => {
  const [{ value }, copyToClipboard] = useCopyToClipboard()

  const share = () => {
    if (navigator.share) {
      navigator.share({
        title: listing.title,
        text: `See ${listing.title} on Vitrine! \n ${window.location.href}`,
        url: window.location.href,
      })
    } else {
      copyToClipboard(window.location.href)
    }
  }

  return (
    <>
      <button onClick={share} justdesktop='true'>
        <span>{ value ? <><i>✔</i> Link copied!</> : <><i>↥</i>Share</> }</span>
      </button>
      <button onClick={share} justmobile='true' className={styles.btn}>
        <span>{ value ? <i>✔</i> : <i>↥</i> }</span>
      </button>
    </>
  )
}
