import styles from './index.module.css'

import { useState, useRef } from 'react'


export const CodeInput = ({ onInput, error, count, control }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const refs = [...Array(count)].map(() => useRef(null))

  const [input, setInput] = useState([...Array(count)].map(() => ' ').join(''))

  const cleanup = event => event.target.value = ''
  const record = (event, index) => {
    if (event.key >= '0' && event.key <= '9') {
      event.target.value = event.key
    } else {
      event.target.value = ''
    }

    const newVal = input.substr(0, index) + (event.target.value || ' ') + input.substr(index + 1)
    setInput(newVal)
    if (onInput) {
      onInput(newVal)
    }

    if (index < refs.length - 1) {
      refs[index + 1].current.focus()
    }
  }

  if (control) {
    control.current = {
      focus: () => refs[0].current.focus()
    }
  }

  return (
    <div>
      <div className={styles.row}>
        {refs.map((ref, index) =>
          <input type='tel' ref={ref} pattern="[0-9]*" key={index}
            className={error ? 'has-error' : ''}
            onKeyDown={cleanup}
            onKeyUp={event => record(event, index)}/>
        )}
      </div>
      {error && <p className='input-error'>{error}</p>}
    </div>
  )
}
