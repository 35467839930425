import { useState, useRef } from 'react'
import { useModal } from '../../util/modal'
import { useAPI } from '../../api'

import { CodeInput } from './code-input'


export const useCodeModal = () => {
  const api = useAPI()
  const input = useRef()
  const [code, setCode] = useState('')
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(false)


  const confirm = async () => {
    setLoading(true)
    setError(undefined)

    const res = await fetch(`${api.auth}?code=${code}&email=${modal.params.email}`)
    setLoading(false)

    if (res.ok) {
      const token = (await res.json()).token
      modal.close(token)
    } else {
      setError((await res.json()).message)
    }
  }

  const modal = useModal(
    <>
      <p>
        A confirmation code has been sent to your email-address. Please enter the confirmation code here.
      </p>
      <br/>
      <CodeInput control={input} count={6} onInput={code => setCode(code)} error={error}/>
      <br/>
      <button onClick={confirm}>
        {loading ? 'Confirming login code ...' : <>✔ Confirm</>}
      </button>
    </>
  )

  modal.onOpen(() => input.current.focus())

  return modal
}
