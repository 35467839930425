import styles from './index.module.css'
import { useAuth } from '../auth'


export const Footer = ({nolinks, children}) => {
  const auth = useAuth()

  return (
    <div className={styles.footer}>
      { children }

      { !nolinks && <>
        <div justmobile='true' className={styles.spacer}/>

        <a href='mailto:support@vitrine.cloud'>Support</a> |
        <a href='https://vitrine.cloud/privacy'>Privacy</a> |
        <a href='https://vitrine.cloud/terms'>Terms of Use</a>
        { auth.loggedIn && <> | <b onClick={() => auth.logout()}>Logout</b></> }
        <br/>
      </>}
      
      <a href='https://vitrine.cloud'>Copyright © 2022 Vitrine App</a>
    </div>
  )
}