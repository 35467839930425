import { Link, useParams } from 'react-router-dom'

import styles from './index.module.css'

import { useTitle } from 'react-use'
import { Video } from '@vitrin/web-video-player'
import '@vitrin/web-video-player/styles'

import { useMessages } from '../api'
import { useListingData } from './use-listing-data'
import { useListingStats } from './use-listing-stats'
import { TimeSince } from './time-since'
import { ShareButton } from './share-button'
import { ContactButton } from './contact-button'
import { Footer } from '../footer'
import { Price } from './price'
import { GetTheAppButton } from '../footer/get-app'


export const Listing = () => {
  const { id } = useParams()
  const valid = id !== undefined
  const { listing, loading, error } = useListingData(id, !valid)

  useTitle(listing ? `Vitrine | ${listing.title}` : 'Vitrine')
  useListingStats(listing)
  useMessages() // --> precache messages

  if (!valid) {
    return <div>Invalid listing id</div>
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <div className={styles.listing}>
      <div className={`${styles.section} ${styles.video}`}>
        <Video url={listing.video.url} timeline={listing.video.timeline}/>
      </div>

      <div className={`${styles.section} ${styles.texts}`}>
        <h2>{listing.title}</h2>
        <Price price={listing.price}/>

        <p>Posted <TimeSince date={listing.submit_time}/></p>
        <p>{listing.location.address}</p>

        <br/>

        <div justmobile='true' className='spacer'/>

        <ContactButton listing={listing}/>
        <ShareButton listing={listing}/>
        
        <hr justdesktop='true'/>

        <Footer>
          <small justdesktop='true'>
            This listing was submitted via the Vitrine App, where you can find the latest listings from the community.
            You can also submit your own listings and find the best deals in your area.
            <br/><br/>
          </small>
          <div className='btn-row'>
            <Link to={'/listing'} className='btn'>See More ...</Link>
            <GetTheAppButton/>
          </div>
          <br justdesktop='true' /><br/>
        </Footer>
      </div>
    </div>
  )
}
