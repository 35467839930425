import { createContext, useContext, useEffect, useRef, useState } from 'react'


const MemStorageContaxt = createContext({})


export const MemStorageAware = ({children}) => {
  const storage = useRef({})

  return (
    <MemStorageContaxt.Provider value={{ storage }}>
      {children}
    </MemStorageContaxt.Provider>
  )
}


export const useMemState = (key, initial) => {
  const { storage } = useContext(MemStorageContaxt)
  const [value, setValue] = useState(storage.current[key] || initial)

  useEffect(() => {
    storage.current[key] = value
  }, [storage, key, value])

  return [
    value,
    setValue
  ]
}


export const useMemStorage = () => {
  const { storage } = useContext(MemStorageContaxt)
  return storage.current
}
